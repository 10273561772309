/* eslint-disable no-promise-executor-return */
/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react'
import { useMountEffect } from 'utils'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg'
import terms from 'assets/terms'
import { Request } from 'types'
import { getFolderName, getFolderSize, getPresignedUrl, postRequest, validateRequest } from 'services'
import {
  Button, ButtonSize, ButtonStyle, Loader, RequestModal, ToastLevel, ToastSignal,
} from 'components'
import RequestError from './RequestError'
import { ModalSignal } from '../ModalWrapper'

import './RequestModal.scss'

type Props = {
  files: File[]
}

export default function RequestUpload({ files }: Props) {
  const [loading, setLoading] = useState(true)
  const [request, setRequest] = useState<Request>(null)
  const [uploadedCount, setUploadedCount] = useState(0)
  const [uploadedMb, setUploadedMb] = useState(0)
  const abortController = new AbortController()

  const handleClose = async () => { ModalSignal.value = undefined }
  const handleCancel = () => { ModalSignal.value = <RequestModal /> }
  const handleError = (error: string) => { ModalSignal.value = <RequestError files={files} message={error} /> }

  const handleValidate = async () => {
    await validateRequest(request)
    handleClose()
  }

  const uploadToS3 = async (file: File) => {
    const presignedUrl = await getPresignedUrl(request, file, abortController.signal)
    const { url, fields } = presignedUrl || {}

    if (!url || !fields) {
      throw new Error(`Échec de l'upload du fichier: ${file.name}`)
    }

    // Create a FormData object
    const formData = new FormData()

    // Append the fields to the formData
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key])
    })

    // Append the file to the formData
    formData.append('file', file)

    // Use fetch to send the PUT request to S3
    const response = await fetch(`${url}`, {
      method: 'POST',
      body: formData,
      signal: abortController.signal,
    })

    if (!response.ok) {
      throw new Error(`Échec de l'upload du fichier: ${file.name}`)
    }

    setUploadedCount(prev => prev + 1)
    setUploadedMb(prev => prev + file.size / 1024 / 1024)
  }

  useMountEffect(() => {
    const handlePostRequest = async () => {
      const response = await postRequest(getFolderName(files))
      if (!response) handleError('Échec de la création de la demande')
      else setRequest(response)
    }

    if (!request) handlePostRequest()
  })

  useEffect(() => {
    const uploadFiles = async () => {
      await files.reduce(async (previousPromise, file) => {
        try {
          // Wait for the previous upload to complete before starting the next
          await previousPromise
          await new Promise(_ => setTimeout(_, 1000))
          await uploadToS3(file)
        } catch (error) {
          // Prevent multiple error toasts
          if (abortController.signal.aborted) return
          ToastSignal.value = {
            severity: ToastLevel.ERROR,
            message: error?.message,
          }
          // Display ErrorModal, will trigger the cleanup function that aborts the signal
          handleError('Échec de lors de l\'upload des fichiers')
        }
      }, Promise.resolve())
      setLoading(false)
    }

    if (request) uploadFiles()

    return () => {
      if (request) { abortController.abort() }
    }
  }, [request])

  return (
    <>
      <h3>
        {terms.Modals.Request.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <p
        className="modal-info"
        dangerouslySetInnerHTML={{
          __html: loading ? terms.Modals.Request.infoUpload : terms.Modals.Request.infoSuccess,
        }}
      />
      <div className="folder-info">
        <p className="files-count">{`${uploadedCount} / ${files.length} ${terms.Modals.Request.uploaded}`}</p>
        {loading ? <Loader variant="small" /> : <SuccessIcon />}
        <div>
          <b>{getFolderName(files)}</b>
          <span>{`${Math.round(uploadedMb)} / ${getFolderSize(files)}  MB`}</span>
        </div>
      </div>
      {loading ? (
        <Button
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          text={terms.Common.cancel}
          onClick={handleCancel}
        />
      ) : (
        <Button
          size={ButtonSize.medium}
          text={terms.Common.validate}
          onClick={handleValidate}
        />
      )}
    </>
  )
}
