/* eslint-disable max-len */
export const terms = {
  Common: {
    disconnect: 'Déconnexion',
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
    cancel: 'Annuler',
    abort: 'Abandonner',
    save: 'Sauvegarder',
    back: 'Retour',
    confirm: 'Confirmer',
    delete: 'Supprimer',
    add: 'Ajouter',
    edit: 'Modifier',
    search: 'Rechercher',
    searching: 'Recherche',
    reset: 'Réinitialiser',
    filter: 'Filtrer',
    close: 'Fermer',
    continue: 'Continuer',
    validate: 'Valider',
    retry: 'Réessayer',
    new: 'Nouveau',
    create: 'Créer',
    export: 'Exporter',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      badGateway: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
  },
  Header: {
    tabs: {
      requests: 'Gestion des demandes',
      rules: 'Règles de validation',
    },
  },
  Pages: {
    Home: {
      content: 'Home Page - Click me',
      newRequest: 'Nouvelle demande',
      noRequests: 'Il n’y a pas de demande en cours, veuillez faire une nouvelle demande.',
      requests: {
        import: 'Importer EP màj',
        importAgain: 'Ré-importer l’EP',
        downloadEp: 'Excel Pivot',
        downloadLastEp: 'Télécharger l’EP modifié',
        export: 'Exporter vers la GED',
        name: 'Nom',
        status: 'Statut',
        lastUpdate: 'Dernière modification',
        exporting: 'Export en cours...',
        exportError: 'Erreur lors de l’export',
        exportComplete: 'Export complet',
        deleted: 'Demande supprimée',
        statusList: {
          UPLOADING: 'État initial',
          CREATED: 'En attente de fichier pivot',
          EXPORTABLE: 'En attente du traitement',
          EP_ERROR: 'En attente fichier pivot corrigé',
          EXPORT_ERROR: 'Erreur lors de l’export',
          EXPORTING: 'En cours de traitement',
          EXPORT: 'ROR - Traitement terminé avec erreur',
          EXPORTED: 'Traitement terminé avec succès',
          DELETING: 'Suppression en cours',
        },
      },
      toggle: 'Utilisateur',
    },
    Admin: {
      search: 'Chercher le nom du groupe',
      toggle: 'Admin',
      globalRules: 'Règles de validation globales',
      createGroup: '+ Créer un groupe',
      rule: (group: string) => `Règles de validation pour le groupe ${group}`,
    },
  },
  Components: {
    Upload: {
      info: (type: string) => `Glissez-déposez le ${type} ici ou<br /><b>sélectionnez le ${type} sur votre ordinateur</b>.`,
    },
  },
  Modals: {
    Request: {
      title: 'Créer une nouvelle demande',
      requestName: 'Nom de la demande (facultatif)',
      infoMain: 'Veuillez importer votre premier dossier.<br />Ses fichiers ne doivent pas dépasser 250Mo.',
      infoMain2: 'Ou créer une demande vide en renseignant son nom ci-dessous.',
      infoUpload: 'Cela peut prendre quelques minutes si le dossier est volumineux.',
      infoSuccess: 'Vous pouvez à présent créer la demande pour ce dossier.',
      error: (folder: string) => `Le dossier <b>${folder}</b> n’a pas pu être importé.<br />Veuillez recommencer l’import avec un nouveau dossier.`,
      uploaded: 'fichiers importés',
    },
    Excel: {
      title: 'Importer un Excel Pivot modifié',
      error: 'Erreur lors de l’import du fichier, veuillez réessayer.',
      uploaded: 'fichier importé',
    },
    Export: {
      title: 'Exporter vers la GED',
      info: (folder: string) => `Vous êtes sur le point de commencer l’export vers la GED du dossier <b>${folder}</b>.<br />Cela peut prendre du temps, vous serez informé de la fin du transfert.`,
    },
    Delete: {
      title: 'Suppression du dossier',
      success: 'Cette action est irréversible mais n’aura pas d’incidence sur les fichiers de ce dossier qui a été totalement exporté dans la GED.',
      error: 'Attention, vous êtes sur le point de supprimer un dossier qui n’a pas été exporté vers la GED de manière complète.<br />La suppression de la demande est définitive.',
    },
    Confirm: {
      title: 'Confirmation requise',
    },
    CreateGroup: {
      title: 'Créer un groupe',
      info: 'Créer un groupe afin de lui attribuer des règles de validation pour vos utilisateurs.',
    },
  },
  Messages: {
    Upload: {
      onFileOnly: 'Veuillez déposer un fichier uniquement.',
      oneFolderOnly: 'Veuillez déposer un dossier uniquement.',
      noFilesFoundInFolder: 'Aucun fichier trouvé dans le dossier déposé.',
      noFilesFound: 'Aucun fichier trouvé.',
      fileSizeLimit: 'Un fichier dépasse la limite de 250Mo.',
      failedExcel: 'Erreur lors de l’import du fichier Excel.',
      successExcel: 'Le fichier Excel a été importé avec succès.',
    },
    Download: {
      failed: (type: string) => `Erreur lors du téléchargement du fichier ${type}.`,
    },
  },
} as const

export default terms
