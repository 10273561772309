/* eslint-disable react/no-danger */
import { useState } from 'react'
import terms from 'assets/terms'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { Button, ButtonSize, FolderUpload, ModalSignal, TextInput } from 'components'
import { postRequest, validateRequest } from 'services'
import RequestError from './RequestError'
import RequestUpload from './RequestUpload'

export default function RequestModal() {
  const [requestName, setRequestName] = useState('')

  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleUpload = (files: File[]) => {
    ModalSignal.value = <RequestUpload files={files} />
  }

  const handleError = (error: string, files: File[]) => {
    ModalSignal.value = <RequestError files={files} message={error} />
  }

  const handleCreateRequest = async () => {
    const response = await postRequest(requestName)
    await validateRequest(response)
    handleClose()
  }

  return (
    <>
      <h3>
        {terms.Modals.Request.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <p className="modal-info" dangerouslySetInnerHTML={{ __html: terms.Modals.Request.infoMain }} />
      <FolderUpload onError={handleError} onUploadReady={handleUpload} />
      <p className="modal-info" dangerouslySetInnerHTML={{ __html: terms.Modals.Request.infoMain2 }} />
      <TextInput label={terms.Modals.Request.requestName} bindedValue={requestName} onChange={setRequestName} />
      <Button
        size={ButtonSize.medium}
        text={terms.Common.validate}
        disabled={!requestName}
        onClick={handleCreateRequest}
      />
    </>
  )
}
